import React from "react";
import { Grid, Typography } from '@mui/material';
import SVGBottom from '../../assets/svgs/bottom.svg'



const AppFooter = () => {
    return (
        <Grid container sx={{
            
            bottom: 0,
            marginBottom:-5,

        }}>
            <img
                src={SVGBottom}
                alt=""
                style={{
                    minWidth: '100%',
                    width: '100%',
                    transform: 'rotate(180deg)'
                }}
            />
            <Grid container sx={{
                background: '#1d4a76',
                marginTop: -0.5,
                padding: 1
            }}>
                <br />

                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                    <Typography color={"#fccc04"} variant="h6">Contáctanos</Typography>
                    <Typography color={"#F1F1F1"} variant="subtitle2">771-126-10-90</Typography>
                    <Typography color={"#F1F1F1"} variant="subtitle2">josueh@stkn.mx</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                    <Typography color={"#fccc04"} variant="h6">Visiatanos en</Typography>
                    <Typography color={"#F1F1F1"} variant="subtitle2">C. 18 de Marzo 1507, Talleres, 64480 Monterrey, N.L.</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                    <Typography color={"#F1F1F1"} variant="subtitle2">Copyright © 2024 Starken - Todos los derechos reservados.  </Typography>
                </Grid>
            </Grid>


        </Grid>
    )

}

export default AppFooter;