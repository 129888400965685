import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IUserInfo {
  id: number;
  name: string
  email: string;
  role: string;
  screens: string[]
}

type AuthContextType = {
  isAuthenticated: boolean;
  userInfo: IUserInfo;
  login: (data: IUserInfo) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUserInfo>(null);

  const login = (data: IUserInfo) => {
    setIsAuthenticated(true);
    setUserInfo(data);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserInfo(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userInfo, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de AuthProvider');
  }
  return context;
};
