

type AnyObject = { [key: string]: any };

export function renameAttribute<T extends AnyObject>(
    list: T[],
    oldAttr: keyof T,
    newAttr: string
): AnyObject[] {
    return list.map(item => {
        const { [oldAttr]: oldValue, ...rest } = item;
        return {
            ...rest,
            [newAttr]: oldValue
        };
    });
}


export function deleteByKey<T>(lista: T[], key: keyof T, valor: any): T[] {
    return lista.filter(item => item[key] !== valor);
}


type DataObject = {
    key: string;
    name: string;
    path: string;
    icon: any;
};

export function filterArrayByKeys(dataArray: string[], objectsArray: DataObject[]): DataObject[] {
    return objectsArray.filter(obj => dataArray.includes(obj.key));
}

type DataDasboardByHours = {
    hour: number;
    count: number;
}

export function formatDashboardByHours(dashboardByHours) {
    // Inicializar un array de 24 elementos con ceros
    const hourlyData = Array(24).fill(0);

    // Asignar los valores del inputData al array hourlyData
    dashboardByHours.forEach(data => {
        hourlyData[data.hour] = data.count;
    });;

    return hourlyData;
}

export function formatDashboardByMonth(dashboardByMonth) {
    // Inicializar un array de 24 elementos con ceros
    const monthlyData = Array(12).fill(0);

    // Asignar los valores del inputData al array hourlyData
    dashboardByMonth.forEach(data => {
        monthlyData[data.month - 1] = data.count;
    });;

    return monthlyData;
}


export function dateTimeFormat(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', '');
}

export function formatDateYMD(date: Date): string {
    // Reorganiza y ajusta el formato a "YYYY-MM-DD HH:MM"
    const [day, month, year, time] = dateTimeFormat(date).split(/[/,\s:]/);
    return `${year}-${month}-${day} ${time}:${dateTimeFormat(date).split(':')[1]}`;
}

export function divideSafelyScrap(badPieces: number, total: number): number {
    if (total === 0) {
        return 0;
    }
    return badPieces / total * 100;
}

export function formatCount(count) {
    if (!count) return 0;
    return count.toLocaleString('es-MX') + ' Pza';
}