import * as React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import ProtectedRoute from './context/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import MainLayout from './components/footer/footer';

const LoginPage = React.lazy(() => import('./pages/Login/Login'));
const DashboardPage = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const HardwarePage = React.lazy(() => import('./pages/Hardware/Hardware'));

const FixturasScreen = React.lazy(() => import('./pages/Maquinado/FixturasScreen'));
const FixturaScreen = React.lazy(() => import('./pages/Maquinado/FixturaScreen'));

const BoardPage = React.lazy(() => import('./pages/Boards/Board'));

const UnauthorizedPage = () => <p>UnauthorizedPage</p>;
const Loading = () => <p>Loading ...</p>;

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute requiredRole={['Produccion', 'Gerente', 'Mantenimiento']}>
            <DashboardPage />
          </ProtectedRoute>
        ),
      }
    ],
  },
  {
    path: '/componentes', // Rutas que incluyen el layout principal con footer
    element: <MainLayout />, // Envolver en el layout con footer
    children: [
      {
        path: '/componentes', // Página de inicio
        element: (
          <ProtectedRoute requiredRole={['Gerente']}>
            <HardwarePage />
          </ProtectedRoute>
        ),
      }
    ],
  },
  {
    path: '/fixturas', // Rutas que incluyen el layout principal con footer
    element: <MainLayout />, // Envolver en el layout con footer
    children: [
      {
        path: '/fixturas', // Página de inicio
        element: (
          <ProtectedRoute requiredRole={['Gerente']}>
            <FixturasScreen />
          </ProtectedRoute>
        ),
      }
    ],
  },
  {
    path: '/fixtura/:id', // Rutas que incluyen el layout principal con footer
    element: <MainLayout />, // Envolver en el layout con footer
    children: [
      {
        path: '/fixtura/:id', // Página de inicio
        element: (
          <ProtectedRoute requiredRole={['Gerente']}>
            <FixturaScreen />
          </ProtectedRoute>
        ),
      }
    ],
  },
  {
    path: '/tableros', // Rutas que incluyen el layout principal con footer
    element: <MainLayout />, // Envolver en el layout con footer
    children: [
      {
        path: '/tableros', // Página de inicio
        element: (
          <ProtectedRoute requiredRole={['Gerente']}>
            <BoardPage />
          </ProtectedRoute>
        ),
      }
    ],
  },
  {
    path: '/login', // Ruta sin layout, por lo que no tiene footer
    element: <LoginPage />,
  },
  {
    path: '/unauthorized',
    element: <UnauthorizedPage />,
  }
]);

const App: React.FC = () => (
  <div className="App">
    <React.Suspense fallback={<Loading />}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </React.Suspense>
  </div>
);

export default App;
