import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HardwareIcon from '@mui/icons-material/Hardware';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import { useNavigate } from 'react-router-dom';
import { filterArrayByKeys } from '../../utils/utils';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '@mui/material/styles';

const MENUS = [{
  "key": "dashboard",
  "name": "Dashboard",
  "path": "/",
  "icon": <DashboardIcon />
},
{
  "key": "fixturas",
  "name": "Fixturas",
  "path": "/fixturas",
  "icon": <PrecisionManufacturingIcon />
},
{
  "key": "tableros",
  "name": "Tableros",
  "path": "/tableros",
  "icon": <MonitorWeightIcon />
},
{
  "key": "hardware",
  "name": "Componentes",
  "path": "/componentes",
  "icon": <HardwareIcon />
}
]

type Anchor = 'left';

export default function DrawerComponent() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false,
  });
  const [menusData, setMenusData] = useState([]);
  const { userInfo } = useAuth();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {Array.isArray(menusData) && menusData.map(({ name, path, icon }, index) => (
          <ListItem key={name} disablePadding onClick={() => {
            navigate(path);
            toggleDrawer(anchor, false)
          }}>
            <ListItemButton>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    const newMenus = filterArrayByKeys(userInfo?.screens || [], MENUS);
    setMenusData(newMenus);
  }, []);

  return (
    <div style={{
      marginTop: -10
    }}>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} style={{ marginTop: 15 }}>
            <MenuIcon sx={{
              color: '#fae38c'
            }} />
          </IconButton>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
