import { createTheme } from "@mui/material/styles";

const PRIMARY_MAIN = "#1d4a76"
const SECONDARY_MAIN = "#dcbc54";
const PRIMARY_LIGHT = "#27649f"

const theme = createTheme({
  palette: {
    primary: {
      //main: "#1d4a76",
      light: "#47739F",
      //dark: "#4c749c"
      main: PRIMARY_MAIN
    },
    secondary: {
      main: SECONDARY_MAIN,
      //main: "#946c04",
      //light: "#F2E6BD",
      //light: '#FBF3D9'
      light: '#FFFBEC'

      //dark: "#7f7143"
    },
    common: {

    }
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          color: PRIMARY_MAIN
        },
        h6: {
          color: PRIMARY_MAIN
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
        }
      }
    },

  },
});

export default theme;