import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useAuth } from '../../context/AuthContext';
import ITWLogo from '../../assets/logos/logo_itw.svg'
import StarkenLogo from '../../assets/logos/logo_appbar.png'
import DrawerComponent from '../Drawer/Drawer';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const Header: React.FC = () => {
  const theme = useTheme();
  const { logout, userInfo } = useAuth();

  return (
    <AppBar
      position="fixed"
      style={{ background: theme.palette.primary.main, boxShadow: 'none' }}
      enableColorOnDark
      color="inherit"
      elevation={0}
    >
      <Toolbar>
        <Grid container sx={{
          gap: 2
        }}>
          <Grid item xs={1} sm={1} md={0.5} lg={0.5} xl={0.5} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}>
            <DrawerComponent />
          </Grid>

          <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <img
              src={StarkenLogo}
              alt="Logo"
              style={{
                width: '150px',
                height: '30px',
              }}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={2} lg={2} xl={2} sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
            <Typography sx={{
              fontSize: 20,
              color: 'white',
              fontWeight: 600,
            }} >Tablero IOT</Typography>
          </Grid>

          <Grid xs={4} sm={4} md={7.5} lg={8} xl={8} sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 1
          }}>
            <Typography sx={{
              fontSize: 20,
              color: 'white',
              fontWeight: 600,
            }}>
              {
                userInfo?.role
              }
            </Typography>

            <IconButton onClick={logout}>
              <LogoutIcon htmlColor='#fb1341' fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )

}
export default Header;