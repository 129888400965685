import React from 'react';
import { Outlet } from 'react-router-dom'; // Para renderizar las rutas anidadas
import DenseAppBar from '../AppBar/AppBar';
import { Box, CssBaseline, styled, Grid, Typography } from '@mui/material';
import AppFooter from '../AppFooter/AppFooter';
import ScrollToTop from '../../hooks/ScrollTop';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'theme' })(({ }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));


const FooterV2: React.FC = () => (
  <Grid container style={{ backgroundColor: '#04347c' }} padding={5}>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
      <Typography color={"#fccc04"} variant="h6">Contáctanos</Typography>
      <Typography color={"#F1F1F1"} variant="subtitle2">771-126-10-90</Typography>
      <Typography color={"#F1F1F1"} variant="subtitle2">josueh@stkn.mx</Typography>
    </Grid>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
      <Typography color={"#fccc04"} variant="h6">Visiatanos en</Typography>
      <Typography color={"#F1F1F1"} variant="subtitle2">C. 18 de Marzo 1507, Talleres, 64480 Monterrey, N.L.</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
      <Typography color={"#F1F1F1"} variant="subtitle2">Copyright © 2024 Starken - Todos los derechos reservados.  </Typography>
    </Grid>
  </Grid>
);


const Footer: React.FC = () => (
  <footer style={{ textAlign: 'center', padding: '1rem', backgroundColor: '#f1f1f1' }}>
    <p>Este es el footer</p>
  </footer>
);


const MainLayout: React.FC = () => (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />

    <DenseAppBar />
    <Main
      style={{
        backgroundColor: '#F9FAFB',
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        //paddingTop: '5px',
        marginTop: '88px',
        marginRight: '20px',
        marginLeft: '20px',
        borderRadius: `12px`
      }}
    >
      <ScrollToTop>
        <Outlet />
        <br />
        {
          //<FooterV2 />
          <AppFooter />
        }
      </ScrollToTop>
    </Main>

  </Box>
);

export default MainLayout;
