import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

type ProtectedRouteProps = {
  children: React.ReactNode;
  requiredRole?: string[]; // Rol requerido para esta ruta
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRole }) => {
  const { isAuthenticated, userInfo } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirigir a la página de inicio de sesión si no está autenticado
  }

  if (requiredRole && !requiredRole.includes(userInfo.role)) {
    return <Navigate to="/unauthorized" />; // Redirigir a la página de acceso no autorizado si el rol no coincide
  }

  return <>{children}</>; // Si todo está bien, muestra los hijos
};

export default ProtectedRoute;
